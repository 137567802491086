.naxxa-bookmarks {
  width: 260px;

  .content {
    padding: 24px 10px 24px 16px;
    .items {
      max-height: 150px;
      min-height: 70px;
      overflow-y: auto;
      padding-left: 8px;
      padding-top: 8px;
      padding-right: 10px;

      .bookmark {
        &-item {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding: 8px 0;
        }

        &-name {
          cursor: pointer;
          width: 80%;
        }
      }
    }
  }

  .footer {
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    padding: 8px 16px;

    button {
      border-radius: 16px;
      width: 100%;
    }
  }
}
