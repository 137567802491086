.authentication {
  &-form {
    align-items: center;
    display: flex;
    height: 97vh;
    justify-content: center;

    & &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 40px;
      max-width: 450px;
      background-color: #fff;
      border-radius: 30px;
      
    }

    & &-img {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
    }

    & &-title {
      color: #303030;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
    }

    & &-subtitle {
      color: #303030;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      text-align: left;
    }

    .ant-form-item-control-input-content > input,
    .ant-input-password {
      border: 2px solid #e0e1e4;
      border-radius: 12px;
      padding: 10px 15px;

      &:hover {
        border: 2px solid rgb(123, 181, 198);
        box-shadow: none;
      }

      &:focus,
      &:hover {
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem rgba(68, 144, 223, 0.25);
        outline: 0;
      }
    }

    .ant-form-item-label > label {
      margin-top: 15px;
    }

    .ant-form-item button[type='submit'] {
      align-items: center;
      border: none;
      border-radius: 12px;
      color: $white;
      display: flex;
      font-weight: 600;
      justify-content: center;
      padding: 23px;
      width: 100%;
    }

    .loginBtnDisabled {
      cursor: no-drop;
      background-color: #d9d9d9;
    }

    .password-item {
      position: relative;

      .forgot-password {
        color: #7bb5c6;
        font-weight: 600;
        letter-spacing: 0;
        position: absolute;
        right: 0%;
        top: -55%;
      }
    }

    .return-to-sign-in {
      align-items: center;
      display: flex;
      justify-content: center;

      > a {
        color: #7bb5c6;
        font-weight: 600;
        letter-spacing: 0;
      }
    }
  }
}

.authentication-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background-color: #fff;
}

.authentication-form {
  position: relative;
  z-index: 1;
}

.authentication-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust transparency */
  z-index: 0;
}

.authentication-form {
  position: relative;
  z-index: 1;
  color: white; /* Or any contrasting color */
}

.authentication-form-container.signup-form-container {
  padding: 40px 0px;
  border-radius: 30px;
  height: 81vh;
}

.signup-form-container .signup-new-container {
  overflow-y: auto;
  padding: 0px 40px;
}

// .signup-form {
//   height: 100vh !important;
// }