// Common variables
$grey: #cccccc;
$black: #000000;
$navy: #000080;

.naxxa-radio-group {
  border: 1px solid $grey;
  border-radius: 12px;
  padding: 4px;

  .ant-radio-group {
    display: flex;
  }

  .ant-radio-button {
    &-wrapper {
      align-items: center;
      border: none;
      border-left: none;
      color: $black;
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      height: 25px;
      justify-content: center;
      line-height: 16px;
      text-align: center;
      transition: none;

      &::before {
        width: 0;
      }

      &-checked {
        background-color: $navy;
        border-radius: 9px;
        color: #FFFFFF !important;
        letter-spacing: 0;
      }

      span:last-child {
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
}
