.advanced-filter-drawer {
  .ant-drawer-header {
    background: $white 0% 0% no-repeat padding-box;
    padding: 0;

    .ant-drawer-close {
      padding: 0;
      right: 34px;
      top: 37px;

      .anticon-close {
        color: #707070;
        font-size: 20px;
      }
    }
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-content-wrapper {
    width: 640px !important;
  }

  .naxxa-advanced-filters {
    &-header {
      display: flex;
      flex-direction: column;

      .title-wrapper {
        display: flex;
        padding: 30px 42px 20px 0px;
        justify-content: space-between;

        @media (min-width: 320px) and (max-width: 550px) {
          padding: 20px 42px 20px 0px;
        }

        span {
          color: $black;
        }

        .title {
          margin-right: 24px;
          margin-top: -7px;
        }

        .reset-filters {
          margin-right: 24px;
          text-decoration: underline;
        }
      }

      .subtitle-wrapper {
        border-top: 1px solid #e0e0e0;
        display: flex;
        padding: 1rem 2rem;
      }
    }

    &-tabs {
      height: 100%;

      .ant-tabs {
        &-content {
          height: 100%;
        }

        &-nav {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          margin: 0;
          padding: 9px 24px;

          &-wrap {
            flex: none;
          }
        }

        &-card {
          height: 100%;
        }
      }
    }

    &-content {
      display: flex;
      height: 100%;

      @media (min-width: 320px) and (max-width: 550px) {
        display: block;
      }

      .left-navbar {
        border-right: 1px solid #e0e0e0;
        flex-basis: 33%;
        height: 100%;

        >ul.ant-menu {
          margin-top: 27px;
          padding-right: 16px;

          @media (min-width: 320px) and (max-width: 550px) {
            padding-left: 23px;
            padding-top: 5px;
            width: 50%;
            text-align-last: left;
            display: flex;
          }
        }

        li.ant-menu-item {
          &::after {
            left: 0;
            right: 100%;
          }

          &-group {
            &-list {
              display: flex;
            }
          }
        }
      }

      .content {
        display: flex;
        flex-basis: 67%;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 27px;

        >ul.ant-menu {
          overflow-x: hidden;
          overflow-y: auto;
        }

        .ant {
          &-collapse {
            background-color: $white;
            border: none;
            overflow-x: hidden;
            overflow-y: auto;

            &-header {
              background: #f6f6f6 0% 0% no-repeat padding-box;
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              padding: 10px 24px;

              &::before {
                content: none;
              }

              &::after {
                content: none;
              }

              .ant-collapse-arrow {
                margin: 0;
              }
            }

            &-content {
              border: none;

              &-box {
                padding: 12px 24px;
              }
            }

            &-item {
              background: $white 0% 0% no-repeat padding-box;
              border: 0;
              margin-bottom: 4px;

              .filter-item-wrapper {
                align-items: center;
                color: #707070;
                display: flex;
                justify-content: space-between;
                margin: 12px 0;
                min-height: 32px;

                @media (min-width: 320px) and (max-width: 550px) {
                  width: 55%;
                }

                >.title {
                  align-items: center;
                  display: flex;
                  justify-content: center;
                }

                .advanced-filters {
                  &-dropdown {
                    max-width: 225px;

                    .ant-typography {
                      width: 90%;
                    }
                  }

                  &-input {
                    background: #f7f7f7;
                    border: none;
                    border-radius: 12px;
                    outline: none;
                    padding: 11px 12px;
                    width: 220px;
                  }
                }

                .naxxa-property {
                  width: 220px;

                  // @media (min-width: 320px) and (max-width: 550px) {
                  //   margin-left: -110%;
                  // }
                }
              }
            }
          }
        }

        .advanced-filter-footer {
          background: $white;
          box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
          padding: 8px 16px;

          button {
            align-items: center;
            align-items: center;
            border-radius: 5px;
            color: $white;
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}

// .saved-searches.naxxa-property:hover {
//   background-color: aliceblue;
//   border-radius: 5px;
//   color: $navy;
// }

.saved-searches.naxxa-property {
  border: none;
  padding: 0;

  .anticon-tag {
    margin-right: 8px;
  }

  .anticon-caret-down {
    margin-left: 10px;
  }
}