@import 'variables';

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: $base-font;
  font-size: inherit;
  letter-spacing: -0.33px;
  line-height: inherit;
}

.navbar-header {
  @media (min-width: 320px) and (max-width: 550px) {
    display: grid;
    gap: 5px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

.ant-menu-title-content {
  display: block;

  @media (min-width: 320px) and (max-width: 550px) {
    display: block;
  }
}

.ant-popover-buttons {
  .ant-btn-sm {
    border-radius: 50px;
    padding: 1px 10px;
  }

  .ant-btn-primary {
    padding: 0px;
    padding: 1px 10px;
  }
}

.ant-picker-dropdown {
  z-index: 1100 !important;
}

.ant-back-top-content {
  background-color: #1c7a92;
}

.ant-message-notice-content {
  border-radius: 20px;
}

* {
  box-sizing: border-box;
  // scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $dark-grey;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: $light-grey;
  }
}

.navy {
  color: $navy;
}

.green {
  color: $green;
}

.ant-tooltip-arrow {
  height: 15px;
  width: 15px;
}

.ant-tooltip-inner {
  padding: 2px 8px;
  border-radius: 10px;
  min-height: 10px;
}

.font {
  &-semi-bold * {
    font-weight: 600;
  }

  &-bold * {
    font-weight: 700;
  }

  &-bold-normal * {
    font-weight: 500;
  }

  &-normal * {
    font-size: 14px;
    line-height: 19px;
  }

  &-small * {
    font-size: 12px;
    line-height: 16px;
  }

  &-smaller * {
    font-size: 10px;
    line-height: 14px;
  }

  &-large * {
    font-size: 16px;
    line-height: 22px;
  }

  &-extra-large * {
    font-size: 18px;
    line-height: 25px;
  }
}

button.ant-btn-primary {
  border-radius: 50px;
  padding: 4px 12px;
}

.matched-result-box .footer .ant-btn-primary {
  margin-top: 0;
}

.ant-modal-title {
  color: #1c7a92;
  width: calc(100% - 15px);
}

.ant-modal-footer button.ant-btn-primary {
  border-radius: 0px;
  padding: 0px 10px;
  margin-top: 0px;
}

.ant-modal-body .meta-detail-name {
  color: #949698;
}

.ant-modal-body {
  font-size: 12px;
  line-height: 1.5;
}

.ant-modal {
  top: 30px;
}

.ant-notification-notice {
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-typography {
  margin: 0px 0px 2px !important;
  font-size: 13px !important;
}

.ant-badge-multiple-words {
  padding: 0px 8px;
}

.cust-spin {
  position: sticky;
  display: flex;
  opacity: 1;
  justify-content: center;
  bottom: 10px;
  column-gap: 10px;
}

.parcelid-search {
  max-width: 222px;

  .ant-input {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 8px 16px;
  }

  button.ant-btn-primary {
    margin-top: 0;
    padding: 16px 14px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;

    .anticon-search svg {
      font-size: 16px;
    }
  }
}

.selected-filters-margin {
  @media (min-width: 500px) {
    margin-right: 20px;
  }
}

.top-resetbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 320px) and (max-width: 550px) {
    display: block;
  }
}

.top-resetbtn .reset-filters span {
  color: #1c7a92;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.view-matches-link {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 13px;
}

.view-matches-page-no {
  @media (min-width: 320px) and (max-width: 550px) {
    width: 50%;
  }
}

// .view-matches-prev-icon {
//   cursor: pointer;
//   margin-left: 12px;
//   margin-right: 5px;
// }
// .view-matches-next-icon {
//   cursor: pointer;
//   margin-left: 5px;
// }

// .view-matches-link {
//   cursor: pointer;
//   margin-left: 10px;
//   background-color: $navy;
//   padding: 9px;
//   font-size: 13px;
//   border-radius: 18px;
// }
// .gutter-row {
//   // height: 350px;
// }
.network-area {
  padding: 50px 10px 15px 15px;

  .ant-row {
    @media (min-width: 320px) and (max-width: 550px) {
      display: contents;
    }

    @media only screen and (min-width: 601px) and (max-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .ant-col-8 {
    @media (min-width: 320px) and (max-width: 550px) {
      display: contents;
    }

    @media only screen and (min-width: 601px) and (max-width: 1024px) {
      max-width: max-content;
      justify-self: center;
    }
  }
}

.matching-type {
  padding: 15px 0px;
  text-align: center;
  color: #fff;
  font-size: 28px;
  margin: 0 20px;
  border-radius: 10px;

  a:hover {
    color: #fff;
  }

  @media (min-width: 320px) and (max-width: 550px) {
    // display: flex;
    // width: fit-content;
  }

  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    padding: 15px 50px;
  }
}

.ant-popover {
  @media (min-width: 320px) and (max-width: 550px) {
    left: 0px !important;
  }
}

.bookmark-pop-confirm {
  z-index: 99999;
}

.gutter-row ul {
  margin: 22px;
  list-style: none;
  padding: 0;
  // display: flex;
  // width: fit-content;
}

.gutter-row ul li {
  padding: 30px 0;
  border-bottom: 1px solid #ccc;
}

.gutter-row ul li:last-child {
  border-bottom: 0;
}

.type-color {
  color: #fff;
}

.ant-menu.ant-menu-dark {
  background: 0;
}

.ant-menu-item.ant-menu-item-only-child {
  padding: 0px !important;
  font-size: 20px;
  text-align: center;
  font-family: none;
}

.list-item-wrapper {
  .ant-list-item {
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .ant-spin-container {
    padding: 5px;
  }
}

.ant-list {
  line-break: anywhere;
}

.net-list-item {
  border: 1px solid #f0f0f0;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}

.item-list-modal {
  .ant-tabs {
    flex-direction: row;
    @media (max-width: 550px) {
      flex-direction: column;
    }
  }
}

.item-list-modal {
  .ant-drawer-body {
    padding: 10px 70px 0 !important;
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.popupEmptyMsg {
  text-align: center;
  width: 100%;
  margin-top: 100px;
}

.signup-btn {
  display: flex;
  justify-content: flex-end;
  color: #7bb5c6;
  font-weight: 600;
  margin-top: 10px;
}

.signup-form {
  .ant-form-item {
    margin-bottom: 4px;
  }
}

.ant-list-item-meta-description {
  line-break: anywhere;
}

.admin-area {
  padding: 50px 30px 5px 30px;

  @media (min-width: 320px) and (max-width: 550px) {
    padding: 20px;
    font-size: 12px;
  }

  .ant-table-wrapper {
    @media (min-width: 320px) and (max-width: 550px) {
      overflow-x: scroll;
    }
  }

  .ant-table-tbody > tr > td {
    @media (min-width: 320px) and (max-width: 550px) {
      padding: 5px;
    }
  }
}

.admin-area h2 {
  font-size: 30px;
  text-align: center;

  @media (min-width: 320px) and (max-width: 550px) {
    font-size: 20px;
  }
}

.survey-title {
  text-align: center;
  font-size: 20px !important;
  margin-top: 40px !important;
  margin-bottom: 20px !important;
}

.survey-taker-email-title {
  color: gray;
  font-weight: normal;
}

.question-surveys {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.question-item {
  width: 100%;
  border: 1px solid #eee;
  margin-bottom: 15px;
  padding: 15px 30px;
  border-radius: 5px;
}

.question-selected-item {
  width: 100%;
  margin-bottom: 15px;
  padding: 15px 30px;
  text-align: right;
  border-radius: 5px;
  // box-shadow: 1px 1px 2px 1px #1c7a92;
  border: 1px solid #eee;
}

.question-item h3,
.question-selected-item h3 {
  font-size: 18px;
  letter-spacing: 0.6px;
  text-align: left;

  @media (max-width: 991px) {
    text-align: center;
  }
}

.question-item a,
.question-selected-item a {
  width: 130px;
  text-align: center;
}

.question-item img,
.question-selected-item img {
  float: left;
  width: 136px;
}

.switch-profile-button {
  margin-right: 75px;
}

.more-button {
  cursor: pointer;
  margin-bottom: 10px;
  outline: none;
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #1c7a92;
  color: #fff;
}

.meta-data-show-parcels {
  color: #1c7a92;
  text-decoration: underline;
  cursor: pointer;
  font-size: 11px;
  margin-left: 4px;
}

.meta-data-view-query {
  color: #1c7a92;
  text-decoration: underline;
  cursor: pointer;
  font-size: 11px;
  float: right;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-card-body {
  padding: 24px 5px !important;
}

.ant-card-head-title {
  font-weight: bold !important;
  font-size: 14px !important;
}

.gm-style .gm-style-iw-c {
  top: -30px !important;
}

.gm-style .gm-style-iw-tc::after {
  top: -30px !important;
}

.pagination-container {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  left: 0;
  background-color: white;
  padding: 15px;
  @media (max-width: 870px) {
    width: -webkit-fill-available !important;
    width: -moz-available !important;
  }
}

.center-empty {
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.grid-view-on-map-div {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  @media (max-width: 710px) {
    justify-content: flex-start;
    margin-top: 5px;
  }
}

.view-criteria-button-div {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  flex-direction: row;
  @media (max-width: 1013px) {
    flex-direction: column;
  }
}

.view-criteria-button-margin {
  margin-left: 5px;
  @media (max-width: 1013px) {
    margin-left: 0;
    margin-top: 9px;
  }
}

.errror-boundary-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.market-stats-title {
  font-size: 19px !important;
  text-align: center !important;
  background-color: #b8cce4;
  padding: 5px;
}

.market-stats-sub-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  // color: gray !important;
  background-color: #92cddc;
  padding: 5px
}

.mtb-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.mt-20 {
  margin-top: 20px !important;
}