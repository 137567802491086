// Common variables
$grey: #cccccc;
$light-grey: #f5f5f5;

.matched-item-popover {
  min-width: 240px;
  padding: 11px;
  min-height: 40px;

  &-main {
    border-bottom: 1px solid $grey;
    padding-bottom: 16px;
  }

  &-list {
    padding: 16px 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        background-color: $light-grey;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin: 12px 0;
        padding: 8px 16px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        p {
          margin: 0;
        }

        .ant-badge{
          &-multiple-words{
            padding: 2.5px 8px;
          }
        }
      }
    }
  }

  &-btn {
    padding-top: 8px;
    justify-content: center;
    display: flex;
    width: 100%;

    button {
      border-radius: 50px;
      width: 100%;
    }
  }

  &-no-results {
    padding-top: 16px;
    text-align: center;
  }
}