.naxxa-selected-filter-option {
  background: $light-grey;
  border: 1px solid #DDDDDD;
  border-radius: 20px;
  color: #707070;
  margin: 0;
  padding: 7px 12px;

  .ant-tag-close-icon {
    border-radius: 50%;
    color: #ABAAAC;
    margin-left: 8px;
    margin-top: 3px;
  }
}
