%top-spacing {
  margin-top: 80px;
}

.view-more-company-drawer {
  & &-content {
    height: 100vh;

    div[role='tablist'] {
      @extend %top-spacing;
      align-items: flex-start;
      padding: 0 16px;
      width: 290px;

      .ant-tabs-nav-list {
        border: 1px solid #E4E4E4;
        flex: none;

        .ant-tabs {
          &-tab {
            margin: 0;
            padding: 13px 20px;
          }

          &-ink-bar {
            left: 0;
            right: unset;
            width: 3px;
          }
        }

        div[role='tab'] {
          width: 100%;

          .tab-title {
            align-items: center;
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .ant-tabs-extra-content {
        margin-bottom: 16px;
        width: 100%;
      }
    }

    .ant-tabs-content {
      &-holder {
        overflow-x: hidden;
        overflow-y: auto;
      }

      .ant-tabs-tabpane {
        @extend %top-spacing;
        padding: 0 12px 12px !important;

        .title {
          color: $navy;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 20px;
          text-align: left;
          width: 100%;
        }

        .company-content {
          height: 100%;
        }
      }
    }
  }

  & &-close {
    color: #424242;

    .anticon {
      margin-right: 9px;
    }
  }

  .ant-drawer {
    &-content-wrapper {
      width: 1050px !important;
    }

    &-body {
      padding: 0;
    }

    &-close {
      left: 0;
      right: unset;
    }
  }

  .company-summary {
    .ant-divider {
      margin: 16px 0;
    }

    .company {
      &-social-media {
        .anticon {
          svg {
            font-size: 30px;
          }
        }
      }

      &-rating {
        &-value {
          margin-left: 30px;
        }
      }
    }
  }
}
