/* Tablet screen view */
@media only screen and (max-width: 1024px) {
  .ant-drawer-content-wrapper {
    width: 40vw !important;
  }

  .ant-select {
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .matched-result-box header h5 {
    margin: 5px auto;
  }

  .matched-result-box .item-container .single-item .list-desc {
    padding: 10px !important;
  }
}

/* Mobile screen view */
@media only screen and (max-width: 768px) {
  .ant-drawer-body {
    padding: 10px;
  }

  .ant-drawer-content-wrapper {
    width: 60vw !important;
  }

  .pagination-container {
    width: 60vw !important;
  }
}
