.profile-area {
  padding: 50px 30px 5px 30px;
  .p-title {
    display: block;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 0px 50px;
    font-weight: 700;

    @media (min-width: 320px) and (max-width: 550px) {
      padding: 20px;
      font-size: 20px;
    }
  }

  .ant-col-12{
    @media (min-width: 320px) and (max-width: 550px) {
      // padding: 20px;
      // font-size: 20px;
      max-width: 100%
    }
  }
  .profile-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    font-size: 18px;
    margin: 8px 0px;
  }
  .hr-tag {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
  }
  .ant-btn-round {
    padding: 4px 16px;
  }
  .update-btn-group {
    display: flex;
    justify-content: flex-end;
    row-gap: 15px;
    margin-bottom: 15px;
  }
  .ant-btn-sm {
    padding: 0px 12px;
    font-size: 12px;
    height: 20px;
  }
}
