@font-face {
  font-family: 'Helvetica Neue';
  src: url('fonts/HelveticaNeue.ttf') format('truetype');
}
$base-font: 'Helvetica Neue';

/* Color Variables */
$white: #FFFFFF;
$black: #424242;
$grey: #DDDDDD;
$light-grey: #F7F7F7;
$dark-grey: #949698;
$navy: #1C7A92;
$green: #8DC494;
$light-blue: #99C0CE;
