// Common variables
$navy: #000080;
$light-grey: #f5f5f5;
$dark-grey: #333333;
$white: #ffffff;

.naxxa-slider-container {
  justify-content: end;
  margin-top: 15px;
  padding: 2px;

  .header-title {
    color: #424242;
    padding-left: 7px;
    margin-bottom: 7px;
  }

  .font-semi-bold {
    font-weight: 600;
  }

  .naxxa-slider {
    .ant-slider-handle {
      background: $navy;
      border: none;
    }

    &-input {
      background: $light-grey;
      border: none;
      border-radius: 18px;
      color: $dark-grey;
      letter-spacing: 0;
      padding: 0.7rem;

      &s-wrapper {
        align-items: center;
        display: flex;

        > span {
          margin: 0 0.5rem;
        }
      }
    }
  }
}

.ant-slider-tooltip {
  .ant-tooltip-inner {
    background-color: $white;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    font-size: 24px;
    padding: 8px 20px;
  }

  .ant-tooltip-arrow-content {
    background-color: $white;
  }
}
