// Common variables
$light-grey: #f5f5f5;
// $navy: #000080;

// Styles for .naxxa-company-card
.naxxa-company-card {
  border: solid 1px $light-grey;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;

  .company {

    &-title,
    &-main-info p,
    &-secondary-info p {
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      margin: 0;
    }

    &-title {
      font-weight: 600;
      font-size: 12px;
    }

    &-main-info,
    &-secondary-info {
      padding: 10px 0;
    }

    &-image {
      margin-bottom: 15px;
      max-width: 70%;
    }

    &-rating {
      align-items: center;
      display: flex;

      .ant-rate {
        font-size: 7px;

        &-star {
          margin-right: 2.5px;
        }
      }

      >p {
        margin-right: 10px;
      }
    }

    &-social-media {
      display: flex;
      margin-top: 5px;

      .anticon {
        font-size: 17px;
        margin-right: 5px;
      }
    }

    &-see-more {
      color: $navy;
      cursor: pointer;
    }
  }

  div[role='separator'] {
    margin: 0;
  }
}