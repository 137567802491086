// Common variables
$grey: #cccccc;
$white: #ffffff;
$black: #000000;
$navy: #1c7a92;

.naxxa-tabs {
  &.maxed {
    .ant-tabs-nav-list {
      width: 100%;
    }
  }

  .ant-tabs-nav {
    flex-wrap: wrap !important;
  }

  .ant-tabs-nav-list {
    border: 1px solid $grey;
    border-radius: 12px;
    padding: 4px;

    .ant-tabs-tab {
      background: $white;
      border: none;
      border-radius: 9px !important;
      color: $black;
      flex-basis: 0;
      flex-grow: 1;
      font-size: 12px;
      font-weight: 500;
      height: 25px;
      justify-content: center;
      line-height: 16px;

      &-active {
        background: $navy;

        > div[role='tab'] {
          color: $white;
        }
      }
    }
  }

  > div[role='tablist'] {
    &::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.view-query-empty {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}