.naxxa-result-card {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  padding: 16px 10px;
  width: 160px;

  // @media (min-width: 320px) and (max-width: 550px) {
  //   min-width: 88vw;
  // }

  img {
    border-radius: 10px;
    height: 150px;
    margin-bottom: 10px;
    width: 200px;
  }

  .ant-card-body {
    @-moz-document url-prefix() {
      .naxxa-meta-detail {
        height: 250px !important;
        overflow: auto !important;
        scrollbar-width: none !important;
      }
    
      .naxxa-meta-detail::-webkit-scrollbar {
        display: none !important;
      }
    }

    .naxxa-meta-detail {
      height: 250px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}