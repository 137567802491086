.open-drawer-btn {
  align-items: center;
  background: $white;
  box-shadow: 0 5px 4px #00000029;
  display: flex;
  height: 60px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 48%;
  width: 35px;
  z-index: 100000;
}
.leftDrawer.left-drawer {
  background: #99c0ce !important;
  color: #ffffff;
}
.open-drawer-btn.left-drawer {
  background: #99c0ce !important;
  color: #ffffff;
}
.close-drawer-btn.left-drawer {
  background: #99c0ce !important;
  color: #ffffff;
}

.main-drawer {
  .ant-drawer {
    &-header {
      display: none;
    }

    &-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-height: 100vh;
      padding: 16px 17px;
    }
  }
}
.ant-drawer-body {
  overflow: hidden;
  padding: 37px 37px;
  overflow-y: scroll;
}
.main-navbar {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  .site-layout {
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }

  .skeleton-div {
    padding: 0px 20px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .skeleton {
    padding: 15px 0;
  }
  .count-circle-one {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    border-radius: 50%;
    line-height: 120px;
    padding: 40px;
    color: black;
  }
  .count-text {
    color: black;
    font-size: 16px;
    margin-top: 10px;
    font-weight: normal;

    &:hover {
      color: blue;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .matching-close-button {
    display: flex;
    > button {
      border-radius: 5px;
    }
  }

  .matching-count-circle-div {
    // max-height: 300px;
    // overflow-y: scroll;
    padding: 10px 0px;
    margin: 0 20px 25px;
    font-weight: 700;
    font-size: 25px;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .matching-count {
    // max-height: 300px;
    // overflow-y: scroll;
    padding: 10px 0px;
    margin: 0 20px 25px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    border-bottom: 1px solid #ccc;
  }
  .ant-list-item-meta-content {
    padding: 0 10px;
  }
  .matching-count:last-child {
    border-bottom: none;
  }
}

.menu-drawer {
  .ant-drawer-content {
    background: linear-gradient(#99c0ce, #99c0ce, #ffffff);
  }
  .ant-drawer-body {
    padding: 0 !important;
  }
  .ant-menu-title-content a {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 22px;
    letter-spacing: 0.2px;
    line-height: 50px;
    display: block;
    font-family: 'Helvetica Neue';
    @media (min-width: 320px) and (max-width: 550px) {
      font-size: 17px;
    }
  }
  .ant-menu-item-only-child {
    height: auto;
  }
  .ant-menu.ant-menu-dark {
    margin-top: 20px;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    margin: 0 !important;
    padding: 2px !important;
  }
}
.logo {
  text-align: center;
}
.naxxa-logo {
  width: 200px;
  margin: 10px 0;
}
.close-menu {
  .ant-drawer-body {
    padding: 0 !important;
  }
}

.left-drawer-dropdown {
  display: block !important;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  @media (min-width: 320px) and (max-width: 550px) {
    width: 85%;
  }
}

.close-drawer-btn {
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 4px #00000029;
  display: flex;
  height: 60px;
  justify-content: center;
  margin-right: -35px;
  position: absolute;
  right: 0;
  top: 48%;
  transition: all 0.3s ease;
  width: 35px;
}

.ant {
  &-drawer-content {
    overflow: unset;
  }

  &-space-item {
    width: 100% !important;
  }

  &-space-horizontal {
    width: 100%;
  }

  &-select-selector {
    border-radius: 50px;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 50px;
}
.logout-btn {
  padding: 8px;
  cursor: pointer;
  font-size: 24px;
  text-align: center;
  color: #fff;
  @media (min-width: 320px) and (max-width: 550px) {
    font-size: 20px;
  }
}

.ant-popover {
  position: fixed !important;
}
