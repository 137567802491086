.google-map-container {
  height: 100vh;
  width: 100vw;
}

.naxxa-map-item-info-window {
  width: 240px;
}

.clear-button{
  margin-top: 8px;
}

.notification{
  padding: 15px;
  opacity: 1.0;
  margin-top: 0.7%;
  // height: 70px;
  height: auto;
  z-index: 1;
  // width: 25%;
  background-color: #0000008a;
  color: white !important;
  float: right;
  margin-right: 75px;
  min-width: 25%;
  // margin-left: 68%;

  @media (min-width: 320px) and (max-width: 550px) {
    margin-left: 30%;
    width: 70%;
    margin-top: 21%;
    padding: 8px;

  }
}

.ant-alert-message{
  color: white;
  font-weight: 500;
  // @media (min-width: 320px) and (max-width: 550px) {
  //   font-size: 11px !important;
  // }
}

.notification-text{
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
}
  
.notification-button{
  display: flex;
  justify-content: space-between;
  margin-right: 5%;
}
  
.notification-button-text{
  margin-left: 20px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
}
.alert-text {
  font-size: 13px !important;
  margin-top: 5px;

  @media (min-width: 320px) and (max-width: 550px) {
    font-size: 12px !important;
  }
}

.alert-text-medium {
  font-size: 15px !important;
  margin-top: 5px;

  @media (min-width: 320px) and (max-width: 550px) {
    font-size: 12px !important;
  }
}

.gm-fullscreen-control {
  height: 29px !important;
  width: 30px !important;
}