%top-spacing {
  margin-top: 15px !important;

  @media (min-width: 320px) and (max-width: 550px) {
    margin-top: 10px !important;
    overflow: 'overlay';

    // .naxxa-result-card{
    //   min-width: 75vw !important;
    // }
  }
}

.selected-result-drawer {
  .view-query-btn {
    @media (min-width: 320px) and (max-width: 550px) {
      margin-left: 10vw;
    }
  }

  .ant-tabs {
    @media (min-width: 320px) and (max-width: 550px) {
      display: block;
    }
  }

  .ant-tabs-content-holder {
    @media (min-width: 550px) {
      overflow: auto;
      height: 100vh;
    }
  }

  .ant-drawer-body {
    overflow: hidden;
    @media (max-width: 550px) {
      overflow: auto;
    }
    @media (min-width: 551px) {
      overflow: hidden;
    }
  }

  &-content {
    &.empty {
      .ant-tabs-nav-wrap {
        display: none;
      }
    }

    div[role='tablist'] {
      @extend %top-spacing;
      align-items: flex-start;
      padding: 0 16px;
      max-width: 290px;

      @media (min-width: 320px) and (max-width: 550px) {
        // height: 250px;
        // width: 86vw;
        // overflow-y: scroll;
        margin-bottom: 10px;
      }

      .ant-tabs-nav-list {
        border: 1px solid #e4e4e4;
        flex: none;

        @media (min-width: 320px) and (max-width: 550px) {
          overflow-y: scroll;
        }

        &:has(> div.selected-result-drawer-content-empty) {
          border: none;
        }

        .ant-tabs {
          &-tab {
            margin: 0;
            padding: 13px 20px;
            @media (min-width: 320px) and (max-width: 550px) {
              padding: 5px 20px;
            }
          }

          &-ink-bar {
            left: 0;
            right: unset;
            width: 3px;
          }
        }

        div[role='tab'] {
          width: 100%;

          .tab-title {
            align-items: center;
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .ant-tabs-extra-content {
        margin-bottom: 16px;
        width: 100%;
      }
    }

    .ant-tabs-content {
      &-holder {
        overflow-x: hidden;
        overflow-y: auto;

        .selected-result-drawer-content {
          max-height: 100vh;

          &-empty {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100vh;
            justify-content: center;
            width: 100%;
          }
        }
      }

      .ant-tabs-tabpane {
        @extend %top-spacing;
        // padding: 0 12px 12px !important;

        .title {
          color: $navy;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 7px;
          text-align: left;
          width: 100%;
        }
        .matches-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title-btn {
            margin-right: 15px;
          }
          @media (max-width: 550px) {
            flex-direction: column;
          }
        }

        .result-content {
          height: 100%;
          padding-bottom: 140px;
          // overflow-y: scroll !important;
        }
      }
    }
  }

  .ant-drawer {
    &-content-wrapper {
      // width: 1050px !important;

      @media (min-width: 320px) and (max-width: 550px) {
        // width: 85vw !important;
      }
    }

    &-title {
      @media (min-width: 320px) and (max-width: 550px) {
        margin-bottom: 1vh;
      }
    }

    &-header {
      @media (min-width: 320px) and (max-width: 550px) {
        display: block !important;
      }
    }

    &-body {
      // overflow: hidden;
      padding: 0;
    }

    &-close {
      color: $dark-grey;
      font-size: 20px;
      right: unset;
      top: 3%;
    }
  }

  .result-summary {
    margin-bottom: 10px;
    @media (min-width: 320px) and (max-width: 550px) {
      margin-bottom: 0px !important;
    }

    img {
      border-radius: 10px;
      height: 110px;
      margin-bottom: 10px;
      max-width: 100%;
      width: 200px !important;
    }
  }

  .matching-inner-tab {
    div[role='tablist'] {
      width: 100% !important;
      padding: 0 !important;
    }
  }
}
