.matched-result-box {
  background: $white 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-top: 17px;
  max-height: 100vh;
  overflow: hidden;

  .grid-view-button-div {
    margin-left: 6px;
    @media (max-width: 970px) {
      margin-left: 0;
    }
    @media (max-width: 650px) {
      width: 100%;
      margin-top: 5px;
    }
  }

  .grid-view-button {
    @media (max-width: 650px) {
      display: block;
      margin-left: 10px;
    }
  }

  .tab-extra-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 469px) {
      justify-content: flex-start;
    }
  }

  .projects-tab {
    .ant-tabs {
      @media (max-width: 469px) {
        overflow: scroll;
      }
    }
  }

  .ant-tabs {
    flex-basis: 100%;
    overflow: hidden;

    &-nav {
      box-shadow: 0 5px 4px -1px #00000029;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      &-list {
        transform: none !important;
      }
      &-wrap {
        flex: none !important;

        @media (min-width: 320px) and (max-width: 550px) {
          margin-right: 34%;
        }
      }
    }

    &-content-holder {
      overflow-y: auto;
    }
  }

  .ant-list-items {
    flex-basis: 100%;
  }

  .ant-list-item {
    flex-direction: row-reverse;
    justify-content: flex-end;

    img {
      border-radius: 5px;
      cursor: pointer;
      height: 110px;
      margin-right: 10px;
      max-width: 100%;
      width: 200px !important;
    }

    .current-img {
      height: 14px;
      margin-right: 10px;
      width: 13px !important;
    }
  }

  .footer {
    align-items: center;
    box-shadow: 0 -3px 7px 0 #0000001a;
    display: flex;
    justify-content: space-between;
    padding: 10px 24px;

    @media (min-width: 320px) and (max-width: 450px) {
      padding: 0;
    }

    .results-pagination {
      display: flex;
      margin-left: 62px;

      @media (min-width: 320px) and (max-width: 1050px) {
        margin-left: 0;
      }

      p {
        align-self: center;
        margin: 0;
        padding-right: 30px;
      }

      .ant-pagination {
        @media (min-width: 320px) and (max-width: 550px) {
          font-size: 11px;
        }
      }
      .ant-pagination-next {
        @media (min-width: 850px) and (max-width: 1050px) {
          margin-left: 15px;
        }
      }

      .ant-pagination-item {
        @media (min-width: 320px) and (max-width: 450px) {
          width: 21px !important;
          min-width: 10px;
        }

        @media (min-width: 850px) and (max-width: 1050px) {
          width: 21px !important;
          min-width: 10px;
        }

        a {
          @media (min-width: 320px) and (max-width: 450px) {
            padding: 0;
          }
        }
      }

      // .ant-pagination {
      //   &-item {
      //     display: none;
      //   }

      //   &-prev {
      //     margin-right: 20px;
      //   }

      //   &-jump {
      //     &-next {
      //       display: none;
      //     }

      //     &-prev {
      //       display: none;
      //     }
      //   }
      // }
    }

    button.ant-btn-primary {
      border-radius: 50px;
      padding: 6px 24px;
    }
  }
}

.zindex-background {
  z-index: 999;
}

.selected-item-popover {
  .ant-popover {
    &-content {
      border-radius: 10px;
      box-shadow: 0 0 10px 0 #0000001a;
      display: block;
      margin-right: -100px;
      max-width: 250px;
      overflow: hidden;
      overflow-y: auto;
    }

    &-inner {
      @media (max-height: 791px) {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;
      }
    }

    &-inner-content {
      padding: 5px;

      img {
        border-radius: 5px;
        max-width: 210px;
        width: 210px;
      }
    }

    &-arrow {
      height: 15px;
      width: 15px;

      &-content {
        box-shadow: -3px 3px 10px -3px #0000001a;
        height: 12px;
        width: 12px;
      }
    }
  }
}
