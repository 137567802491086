.view-all-drawer {
  // overflow-y: scroll !important;

  .ant-drawer {
    // &-content-wrapper {
    //   overflow-y: scroll !important;
      // width: 100vw !important;
    // }

    &-body {
      padding: 40px 70px;
      // overflow-y: scroll !important;

      @media (min-width: 320px) and (max-width: 550px) {
        padding: 20px;
      }

      .naxxa-tabs {
        .ant {
          &-tabs-nav {
            margin-bottom: 0px;

            &-wrap {
              flex: none;
            }
          }
        }
      }
    }

    &-close {
      color: $dark-grey;
      font-size: 20px;
      right: unset;
      top: 3%;
    }
  }
}

.grid-skeleton-div {
  display: flex !important;
  flex-direction: row !important;
  gap: 15px;

  span {
    flex: 1 !important;
  }
}