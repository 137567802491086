// Common variables
$light-grey: #f5f5f5;
$dark-grey: #333333;

// Styles for .properties-checkbox-group
.properties-checkbox-group {
  .ant-checkbox {
    margin-left: 2rem !important;

    &-checked {
      border-radius: 50%;
      &:hover {
        border-radius: 50%;
      }
      &::after {
        border-radius: 50%;
      }
    }

    &-wrapper {
      align-items: center;
      color: $dark-grey;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 8px;
      transition: all 0.3s ease-out;
      width: 100%;

      &.header-title {
        color: #424242;
        padding-left: 0;
      }

      &:hover {
        background: $light-grey 0% 0% no-repeat padding-box;
      }

      span {
        margin: 0;
        padding: 0;
      }

      &::after {
        display: none;
        margin: 0;
        padding: 0;
      }
    }

    &-group {
      display: flex;
      flex-direction: column;
    }

    &-inner {
      border-radius: 50%;
      height: 20px;
      width: 20px;

      &::after {
        height: 10px;
        left: 25%;
        width: 5px;
      }

      &:hover {
        border-radius: 50%;
      }
    }
  }
}

// Styles for .search-checkbox
.search-checkbox {
  .search-checkbox-input {
    font-weight: 400;
    margin-top: 8px;
  }

  .input-wrapper {
    padding: 2px;
  }

  &-input {
    background: $light-grey;
    border: none;
    border-radius: 12px;
    color: #c6c6c6;
    margin-bottom: 10px;
    outline: none;
    padding: 8px 12px;
    width: 100%;

    &:focus,
    &:hover {
      border: #00000029;
      box-shadow: 0 0 0 2px #00000029;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    min-height: 100px;
    overflow-y: auto;
  }
}

// Styles for .ant-collapse
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center !important;

  @media (min-width: 320px) and (max-width: 550px) {
    padding: 8px 10px;
  }
}

// Additional styles
.properties-checkbox-group > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 2px 16px !important;
}

.current-zoning {
  .properties-checkbox-group > .ant-collapse > .ant-collapse-item {
    border: none !important;
  }

  .search-checkbox-wrapper > .properties-checkbox-group:last-child {
    border-bottom: 1px solid #d9d9d9 !important;
  }
}
