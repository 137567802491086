// Common variables
$primary-color: #4c4c4c;
$light-grey: #fafafa;

.cookie-consent-wrapper {
  position: absolute;
  bottom: 2%;
  right: 2%;
  background-color: white;
  border: 1px solid black;
  width: 30%;
  padding: 4% 3%;
  z-index: 9999;
  > .cookie-accept-button {
    border-radius: 0;
    height: 48px;
    margin-bottom: 10px;
  }
}

.cookie-drawer {
  .cookie-content,
  .manage-consent {
    h5.ant-typography {
      font-size: 16px !important;
    }
  }

  .cookie-content {
    .cookie-description,
    .more-info-link {
      display: block;
      margin-top: 10px !important;

      &.more-info-link {
        text-decoration: underline !important;
      }
    }
  }

  .manage-consent {
    margin: 20px auto !important;
  }

  .cookie-accept-button {
    display: flex;
    border-radius: 0;
    margin-left: auto;
    margin-top: 10px;
  }

  .ant-drawer-body {
    padding: 16px 37px;
  }

  .ant-drawer-header {
    .ant-drawer-title,
    .anticon-close svg {
      color: $primary-color;
      font-weight: bold;
    }
  }

  .preferences {
    .ant-collapse-header-text {
      font-weight: 600;
    }

    .ant-collapse-header,
    .ant-collapse-content {
      background-color: $light-grey;
    }
  }
}

@media (max-width: 425px) {
  .cookie-consent-wrapper {
    right: 5%;
    left: 5%;
    width: 90%;
    margin: auto;
  }
}
