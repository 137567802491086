.user_name {
    cursor: pointer;
    color: #42b3c2;
    font-size: 14px !important;
}

.user-drawer {
    .ant-descriptions-item-label {
        color: #00000073 !important;
        font-size: 14px !important;
    }

    .ant-descriptions-row>th,
    .ant-descriptions-row>td {
        padding-bottom: 5px !important;
    }
}

.update-user-form{
    .ant-input{
        border-radius: 50px;
        height: 42px;
        padding: 6px 20px ;
    }
}