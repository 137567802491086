// Common variables
$dark-grey: #333333;
$black: #000000;
$navy: #000080;

.naxxa-meta-detail {
  word-wrap: break-word;
  flex: 1;
  

  .meta {
    &-title {
      text-align: left;
      text-transform: capitalize;
      display: flex;
      margin-right: 5px;
    }

    &-detail {
      margin-top: 4px;
      text-align: left;

      &-name {
        color: $dark-grey;
      }

      &-value {
        color: $black;
      }
    }
  }

  &-modal {
    .meta-detail {
      &-show-more {
        color: $navy;
        cursor: pointer;
        padding-left: 4px;
        text-decoration: underline;
      }
    }
  }
}

.popup-more-info {
  text-align: center;
}

.ant-modal-wrap {
  z-index: 1100;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px;
  padding: 6px 20px;
}

.ant-select-item-option-content {
  padding: 8px 10px;
}

.clientLebel label {
  font-size: 16px;
  font-weight: 700;
}

.meta {
  &-title {
    text-align: left;
    text-transform: capitalize;
    display: flex;
    margin-right: 5px;
  }

  &-detail {
    margin-top: 4px;
    text-align: left;

    &-name {
      color: #949698;
    }

    &-value {
      color: $black;
    }
  }
}