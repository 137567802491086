.refine-criteria {
  &-container {
    border: 1px solid $grey;
    border-bottom: none;
    border-radius: 10px;
    display: block;
    margin-top: 17px;

    

    .more-filters-btn {
      align-items: center;
      border: 1px solid #d9d9d9;
      border-radius: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: 0 11px;
      padding: 8px 16px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      p {
        color: $navy;
        margin: 0;
        padding: 0 10px;
      }

      .anticon-sliders {
        font-size: 18px;

        svg {
          fill: $navy;
          transform: rotate(90deg);
        }
      }
    }
  }

  &-filters {
    border-top: none;
    box-shadow: 0 3px 7px #00000029;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 23px;
    @media (min-width: 320px) and (max-width: 550px) {
      gap:9px
    }
  }

  &-selected-filters {
    background-color: $white;
    border: none;
    border-radius: 0 0 10px 10px;

    .header {
      margin-bottom: 16px;
    }

    .ant-collapse {
      background: white;

      &-item-active {
        display: flex;
        flex-direction: column-reverse;
      }

      &-header {
        border-radius: 0 0 10px 10px !important;
        border-top: 1px solid $grey;
        display: flex;

        .collapse-header {
          text-align: center;
          width: 100%;
        }
      }

      &-content-active {
        border-top: none;

        .ant-collapse-content-box {
          padding: 0 0 10px 24px;
        }
      }

      &-arrow {
        align-self: center;
      }

      &-item {
        border-radius: 0 0 10px 10px !important;

        svg {
          transform: rotate(90deg) !important;
        }

        &-active {
          svg {
            transform: rotate(270deg) !important;
          }
        }
      }
    }

    &-body {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      max-height: 100px;
      overflow-y: auto;
    }

    &-apply {
      color: $navy;
      display: flex;
      flex-direction: row-reverse;
      text-decoration: underline;
      width: 100%;

      span {
        cursor: pointer;
        margin-right: 30px;
      }
    }
  }
}

.naxxa-property {
  .criteria-checkbox-title {
    color: $dark-grey;
    cursor: pointer;
    font-weight: 700;
    text-align: center;
    text-decoration: underline;
  }
}
.refine-criteria-filters {
  padding: 14px 20px;

  @media (min-width: 320px) and (max-width: 550px) {
    padding: 7px 10px;
  }
}
