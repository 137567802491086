// Common variables
$white: #ffffff;
$light-grey: #f5f5f5;

.naxxa-property {
  &.wrapper {
    background: $white;
    border: 1px solid #dddddd;
    border-radius: 20px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;

    @media (min-width: 320px) and (max-width: 550px) {
      padding: 3px 15px;
    }

    .ant-typography {
      @media (min-width: 320px) and (max-width: 550px) {
        width: 84%;
      }
    }
  }

  .anticon-caret-down {
    font-size: 19px;
    margin-left: 10px;
  }

  .ant-popover-inner {
    border-radius: 8px;
    margin-left: 10px;

    &-content {
      padding: 0;
    }
  }

  .popup-container {
    &.taker-popover {
      width: 300px;
    }
  }

  .popup {
    &-container {
      display: flex;
      flex-direction: column;
      max-height: 500px;
      padding: 25px 16px;
      width: 350px;
    }

    &-footer {
      background: $white;
      border-radius: 0 0 0.7rem 0.7rem;
      box-shadow: 0 2px 25px #00000029;
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      padding: 1rem 2rem;
      width: 100%;

      &-link-reset {
        align-items: center;
        display: flex;

        &:hover {
          cursor: pointer;
        }

        &-text {
          text-decoration: underline;
        }
      }
    }

    &-button {
      border-radius: 30px;
      height: 100%;
      width: 100%;
    }

    &-header {
      &-input {
        background: $light-grey;
        border: none;
        border-radius: 1rem;
        color: #c6c6c6;
        margin-bottom: 10px;
        outline: none;
        padding: 0.8rem 1rem;
        width: 100%;

        &:focus,
        &:hover {
          border: #00000029;
          box-shadow: 0 0 0 2px #00000029;
        }
      }
    }
  }
}

.current-zoning {
  @media (min-width: 320px) and (max-width: 550px) {
    width: fit-content;
  }
}