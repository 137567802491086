// Common variables
$black: #000000;

.naxxa-total-results {
  align-items: center;
  align-self: center;
  color: $black;
  display: flex;
  margin-bottom: 0;
  margin-left: 8px;

  &::before {
    background: $black;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 4px;
    margin-right: 4px;
    width: 4px;
  }

  @media (min-width: 320px) and (max-width: 550px) {
    width: min-content;
  }
}
